<template>
    <main class="main-page">
    <section class="page-section " >
        <div class="container">
            <div class="grid ">
                <div  class="col comp-grid" >
                    <div >
                        <div style="min-height:100px">
                            <template v-if="pageReady">
                                <div class="p-4 mb-2">
                                    <div class="grid align-items-center">
                                        <div class="mr-3">
                                            <Button @click="$router.go(-1)" label="" icon="pi pi-arrow-left"
											class="p-button-sm p-button-rounded px-4" />
                                        </div>
                                        <!-- <div class="col-fixed" style="width:180x">
                                            <image-viewer class="border-round" style="max-width:100%;max-height:100px" width="auto" height="auto" image-size="medium" :src="$auth.userPhoto" />
                                        </div> -->
                                        <div class="col">
                                            <div class="text-3xl font-bold text-primary"> {{ $auth.userName }} </div>
                                            <div class="text-gray-500"> {{ $auth.userEmail }} </div>
                                        </div>
                                    </div>
                                </div>
                                <TabView v-model:activeIndex="activeTab">
                                    <TabPanel>
                                        <template #header>
                                            <i class="pi pi-user mr-2"></i>
                                            <span>Account Detail</span>
                                        </template>
                                        <div>
                                            <div class="col-12">
                                                <div class="grid align-items-center card surface-50 p-3">
                                                    <div class="col-fixed  text-500 font-medium">First Name: </div>
                                                    <div class="col font-bold">{{ item.first_name }}</div>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="grid align-items-center card surface-50 p-3">
                                                    <div class="col-fixed  text-500 font-medium">Middle Name: </div>
                                                    <div class="col font-bold">{{ item.middle_name }}</div>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="grid align-items-center card surface-50 p-3">
                                                    <div class="col-fixed  text-500 font-medium">Last Name: </div>
                                                    <div class="col font-bold">{{ item.last_name }}</div>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="grid align-items-center card surface-50 p-3">
                                                    <div class="col-fixed  text-500 font-medium">Email: </div>
                                                    <div class="col font-bold">{{ item.email }}</div>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="grid align-items-center card surface-50 p-3">
                                                    <div class="col-fixed  text-500 font-medium">Date Created: </div>
                                                    <div class="col font-bold">
                                                        <Button class="p-0 p-button-text p-button-plain" v-if="item.date_created" :label="$utils.relativeDate(item.date_created)" v-tooltip="{value: $utils.humanDatetime(item.date_created)}" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="grid align-items-center card surface-50 p-3">
                                                    <div class="col-fixed  text-500 font-medium">Account Status: </div>
                                                    <div class="col font-bold">{{ item.account_status }}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel>
                                        <template #header>
                                            <i class="pi pi-pencil  mr-2"></i>
                                            <span>Edit Account</span>
                                        </template>
                                        <div class="reset-grid">
                                            <account-edit-page></account-edit-page>
                                            </div>
                                        </TabPanel>
                                        <TabPanel>
                                            <template #header>
                                                <i class="pi pi-lock  mr-2"></i>
                                                <span>Change Password</span>
                                            </template>
                                            <div class="reset-grid">
                                                <change-password-page></change-password-page>
                                            </div>
                                        </TabPanel>
                                    </TabView>
                                </template>
                                <template v-if="loading">
                                    <div  class="q-pa-lg text-center">
                                        <ProgressSpinner style="width:50px;height:50px" />
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </main>
</template>
<script setup>
	import {  ref, toRefs, onMounted } from 'vue';
	import { useApp } from '@/composables/app.js';
	import { useAuth } from '@/composables/auth';
	import { utils } from '@/utils';
	import  AccountEditPage  from "./accountedit.vue";
	import  ChangePasswordPage  from "./changepassword.vue";
	import { useViewPage } from '@/composables/viewpage.js';
	const props = defineProps({
		id: [String, Number],
		primaryKey: {
			type: String,
			default: 'user_id',
		},
		pageName: {
			type: String,
			default: 'users',
		},
		routeName: {
			type: String,
			default: 'usersaccountview',
		},
		apiPath: {
			type: String,
			default: 'account',
		},
		editButton: {
			type: Boolean,
			default: true,
		},
		deleteButton: {
			type: Boolean,
			default: true,
		},
		exportButton: {
			type: Boolean,
			default: true,
		},
		titleBeforeDelete: {
			type: String,
			default: "Delete record",
		},
		msgBeforeDelete: {
			type: String,
			default: "Are you sure you want to delete this record?",
		},
		msgAfterDelete: {
			type: String,
			default: "Record deleted successfully",
		},
		showHeader: {
			type: Boolean,
			default: true,
		},
		showFooter: {
			type: Boolean,
			default: true,
		},
		isSubPage: {
			type : Boolean,
			default : false,
		},
	});
	const app = useApp(props);
	const auth = useAuth();
	const page = useViewPage(props);
	const activeTab = ref(0);
	// page state
	const {  item, loading, pageReady } = toRefs(page.state);
	// page computed propties
	const { apiUrl, currentRecord } = page.computedProps;
	//page methods
	const { load,  deleteItem } = page.methods;
	onMounted(()=>{
		const pageTitle = "My Account";
		app.setPageTitle(props.routeName, pageTitle); // set browser page title
		load();
	});
</script>
<style scoped>
</style>
